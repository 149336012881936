import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Home from "./pages/Home/Home";
import AOS from "aos";
import { Symfoni } from "./hardhat/SymfoniContext";
import ApeClub from "./pages/ApeClub/ApeClub";

AOS.init({
  duration: 600,
});

function App() {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
    }
  });

  function renderApp() {
    return (
      <>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              background: "#121619",
              border: "1px solid #21262a",
              padding: "16px",
              color: "#fff",
            },
          }}
          position="top-right"
        />

        <Switch>
          <Route exact path="/club">
            <Symfoni autoInit={true} showLoading={false}>
              <BaseLayout>
                <ApeClub />
              </BaseLayout>
            </Symfoni>
          </Route>
          <Route exact path="*">
            <BaseLayout>
              <Home />
            </BaseLayout>
          </Route>
        </Switch>
      </>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
