import {
  generateEndpointDistributionRewardsByAddress,
  generateEndpointGetDelegations,
  generateEndpointGetUndelegations,
  generateEndpointGetValidators,
} from "@tharsis/provider";

import {
  generateEndpointBalances,
  generateEndpointAccount,
} from "@tharsis/provider";
import { EVMOS_REST_URL, ACRE_REST_URL } from "../network";
import converter from "bech32-converting"

import { ethToEvmos } from "@tharsis/address-converter";
import { NO_PUBKEY } from "./constants";

export async function getBlockchainInfo(wallet, setStatus, active_chain) {
  let [balances, delegations, rewards, undelegations, validators] =
    await Promise.all([
      getAllBalances(active_chain, wallet),
      getDelegations(active_chain, wallet),
      getDistributionRewards(active_chain, wallet),
      getUndelegations(active_chain, wallet),
      getValidators(active_chain),
    ]);

  setStatus({
    balances: balances.balances,
    delegations: delegations,
    rewards,
    undelegations,
    validators,
  });
}

// function getStakes(){

// }

export async function getValidators(active_chain) {
  const NODE_URL = active_chain === 'evmos' ? EVMOS_REST_URL : ACRE_REST_URL
  try {

    const resp = await fetch(
      `${NODE_URL}${generateEndpointGetValidators()}?status=BOND_STATUS_BONDED&pagination.limit=200`,
      
    );
    const validators = await resp.json();
    if (validators.code) {
      // Node error
      return [];
    }
    const parsed = validators.validators;
    return parsed;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getDistributionRewards(active_chain, address) {
  const NODE_URL = active_chain === 'evmos' ? EVMOS_REST_URL : ACRE_REST_URL
  try {

    const resp = await fetch(
      `${NODE_URL}${generateEndpointDistributionRewardsByAddress(address)}`,
      
    );
    const rewards = await resp.json();
    if (rewards.code) {
      // Node error
      return [];
    }
    const parsed = rewards.rewards;
    return parsed;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getDelegations(active_chain, address) {
  const NODE_URL = active_chain === 'evmos' ? EVMOS_REST_URL : ACRE_REST_URL


  try {
    const resp = await fetch(
      `${NODE_URL}${generateEndpointGetDelegations(address)}`,
      
    );
    const value = await resp.json();
    if (value.code) {
      return [];
    }
    const parsed = value;
    return parsed.delegation_responses;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getUndelegations(active_chain, address) {
  const NODE_URL = active_chain === 'evmos' ? EVMOS_REST_URL : ACRE_REST_URL


  try {
    const resp = await fetch(
      `${NODE_URL}${generateEndpointGetUndelegations(address)}`,
    );
    const value = await resp.json();
    if (value.code) {
      return [];
    }
    const parsed = value;
    return parsed.unbonding_responses;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getAllBalances(active_chain, address) {
  const NODE_URL = active_chain === 'evmos' ? EVMOS_REST_URL : ACRE_REST_URL
  if (address === null) {
    return { balances: [] };
  }

  try {
    const balance = await fetch(
      `${NODE_URL}${generateEndpointBalances(address)}`
    );
    const parsed = await balance.json();
    return parsed;
  } catch (e) {
    console.error(e);
    return { balances: [] };
  }
}

export async function getAccount(wallet) {
  const NODE_URL = wallet.includes('evmos') ? EVMOS_REST_URL : ACRE_REST_URL

  let resp;
  try {
    const addr = await fetch(
      `${NODE_URL}${generateEndpointAccount(wallet)}`
    );
    resp = await addr.json();
    if (resp.code === 5) {
      // If error 400 wallet doesn't exists
      console.error(resp);
      return null;
    }
  } catch (e) {
    // RPC endpoint is dead!
    console.error(e);
    return null;
  }
  return {
    accountAddress: wallet,
    sequence: parseInt(resp.account.base_account.sequence, 10),
    accountNumber: parseInt(resp.account.base_account.account_number, 10),
  };
}


function ethToAcre(address) {
  return converter('acre').toBech32(address)
}

export async function queryPubKey(active_chain, address) {
  const NODE_URL = active_chain === 'evmos' ? EVMOS_REST_URL : ACRE_REST_URL
  let converted = address;
  if (!converted.includes(active_chain)) {
    converted = active_chain === 'evmos' ? ethToEvmos(converted) : ethToAcre(converted)
  }


  let resp;
  try {
    const addr = await fetch(
      `${NODE_URL}${generateEndpointAccount(converted)}`    );
    // If error 400 wallet doesn't exists
    resp = await addr.json();
  } catch (e) {
    console.error(e);
    return NO_PUBKEY;
  }
  if ("account" in resp) {
    if ("base_account" in resp.account) {
      if ("pub_key" in resp.account.base_account) {
        if (resp.account.base_account.pub_key !== null) {
          return resp.account.base_account.pub_key.key;
        }
      }
    }
  }

  return NO_PUBKEY;
}
