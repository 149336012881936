import { useState } from "react";
import DiscordInstructions from "../../components/DiscordInstructions";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { handleError } from "../../helpers/errors";


window?.ethereum?.request({ method: "eth_requestAccounts" });



export default function OwnerDiscord({ linkedData }) {
  const [showInstruction, setShowInstruction] = useState(false);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false)

  async function handleGetRole() {
    setLoading(true)
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {}, // required
    });
    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner();
  
    const signature = await signer
      .signMessage(
        "Hello orbital ape commander, I want to be enrolled as an official ape on our discord ship. I gonna give you some banana for that."
      )
      .catch((error) => {
        handleError(error)
        setLoading(false);
        return;
      });
    
    await fetch(
      "https://communityrpc.nuxian-node.ch/api/link_discord",
      {
        method: "post",
        body: JSON.stringify({ signature: signature, discord_name: userName }),
        headers: { "Content-Type": "application/json" },
      }
    ).catch((error) => {
      handleError(error)
      setLoading(true)
      return;
    });
    setLoading(true)
  }

  return (
    <>
      <div
        style={{ minWidth: "50%" }}
        className="flex  flex-1 gap-2 flex-col p-3 border-darkGray rounded bg-dark border-2 "
      >
        <div className="flex flex-col gap-6">
          <span className="text-center self-center text-white font-semibold text-3xl">
            Get OA Owner Discord Role
          </span>
          {linkedData && (
            <span>
              Your Address is linked to a Discord username
            </span>
          )}
          {!linkedData && (
            <span>
              Your Address is NOT linked to a Discord username
            </span>
          )}
          <div className="flex flex-col md:flex-row gap-2">
            <input
              className="border-gray outline-none flex-1 bg-darkGray rounded px-2"
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter username including the number"
            />
            <button
              disabled={loading}
              className="bg-darkGray px-4 rounded"
              onClick={() => handleGetRole()}
            >          
            {loading ? "Loading" : "Get Owner Role"}
            </button>
          </div>
        </div>
        <button
          onClick={() => setShowInstruction(true)}
          className="text-purple"
        >
          See example
        </button>
      </div>

      {showInstruction && (
        <DiscordInstructions closeModal={() => setShowInstruction(false)} />
      )}
    </>
  );
}
