import cs from "classnames";
import x from "../assets/components/x.svg";

function MobileHeader({ setShow }) {
  return (
    <div
      style={{ backgroundColor: "#0C0C0C" }}
      className={cs("z-50 fixed top-0 bottom-0 flex flex-col left-0 right-0")}
    >
      <button
        onClick={() => setShow(false)}
        className="outline-none z-50 self-end justify-self-end pr-8 pt-8 text-xl"
      >
        <img alt="x" className="w-6 cursor-pointer " src={x} />
      </button>
      <div className="flex pt-20 -mt-10 flex-col justify-start h-full items-center">
        <div className="max-w-7xl items-center text-xl gap-3 pt-8 flex w-full flex-col">
          <a href="https://hub.orbitalapes.com" className=" px-5 py-2 hover:bg-darkGray text-white ">
            OA Hub
          </a>
          <a
            href="https://app.evmos.org/staking"
            className=" px-5 py-2 hover:bg-darkGray text-white "
          >
            Validator
          </a>
          <a
            target="_blank"
            href="https://www.orbitmarket.io/"
            className=" px-5 py-2 hover:bg-darkGray text-white "
            rel="noreferrer"
          >
            Marketplace
          </a>
          <a
            target="_blank"
            href="https://www.orbitrumble.com/"
            className=" px-5 py-2 hover:bg-darkGray text-white "
            rel="noreferrer"
          >
            Rumble
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
