import Cover from "react-video-cover";

const style = {
  width: "100vw",
  height: "calc(100vh - 65px)",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: -1,
};

function Home() {
  const videoOptions = {
    src: "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Cover.mp4",
    autoPlay: true,
    muted: true,
    loop: false,
    playsInline: true,
  };

  return (
    <div className="flex items-center flex-col">
      <div className="flex relative">
        <div className="absolute" style={style}>
          <Cover videoOptions={videoOptions} remeasureOnWindowResize />
        </div>
        <div
          style={{ width: "100vw", height: "calc(100vh - 65px)" }}
          className="flex items-center text-white flex-col"
        >
          <div className="flex top-6 items-center absolute gap-2 flex-col">
            <div className="flex  gap-6 items-center text-5xl md:text-7xl font-semibold  flex-col">
              <div className="flex items-center gap-2 flex-col">
                <div className="flex gap-2.5">
                  <span
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-delay="100"
                  >
                    WELCOME
                  </span>
                  <span
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-delay="500"
                  >
                    TO
                  </span>
                </div>

                <span
                  data-aos="fade-down"
                  data-aos-duration="400"
                  data-aos-delay="1000"
                >
                  ORBITAL APES
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
