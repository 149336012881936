import { connectKeplr } from "../../utils/wallets/keplr";
import { connectMetamask } from "../../utils/wallets/metamask";
import Metamask from "../../assets/icons/metamask.svg";
import Kplr from "../../assets/icons/keplr.png";
import {
  KEPLR_PROVIDER,
  METAMASK_PROVIDER,
  NO_WALLET,
} from "../../utils/wallets/constants";

const WalletConnect = ({
  setProvider,
  setWallet,
  setPubkey,
  wallet,
  provider,
  hideKeplr,
}) => {
  return (
    <div className="gap-3  flex">
      <button
        style={{ backgroundColor: "#0C0C0C" }}
        className="border  gap-3 items-center py-2 flex px-3 rounded-md border-gray"
        onClick={async () => {
          await connectMetamask(setProvider, setWallet, setPubkey);
        }}
      >
        <img src={Metamask} className="h-7" alt="metamask" />
        {(wallet === NO_WALLET || !wallet || provider === KEPLR_PROVIDER) && (
          <>Connect Metamask</>
        )}
        {wallet !== NO_WALLET && wallet && provider === METAMASK_PROVIDER && (
          <>Connected {wallet.substring(0, 8)}...</>
        )}
      </button>
      {!hideKeplr && (
        <button
          style={{ backgroundColor: "#0C0C0C" }}
          disabled={false}
          className="border  gap-3 items-center py-2 flex px-3 rounded-md border-darkGray"
          onClick={async () => {
            connectKeplr(setProvider, setWallet, setPubkey);
          }}
        >
          <img src={Kplr} className="h-7" alt="metamask" />
          {(wallet === NO_WALLET ||
            !wallet ||
            provider === METAMASK_PROVIDER) && <>Connect KEPLR</>}
          {wallet !== NO_WALLET && wallet && provider === KEPLR_PROVIDER && (
            <>Connected {wallet.substring(0, 8)}...</>
          )}
        </button>
      )}
    </div>
  );
};

export default WalletConnect;
