import { errorToast } from "../components/Toasts";

export function handleError(error) {
  if (error.code === 4001 && error.message) return errorToast(error.message);
  if (error?.data?.message) {
    errorToast(error?.data?.message);
  } else {
    errorToast("Something Went Wrong!");
  }
}
