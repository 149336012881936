/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { providers, Signer, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Web3Modal, { IProviderOptions } from "web3modal";
import { HonoraryOrbitalApes } from "./typechain/HonoraryOrbitalApes";
import { HonoraryOrbitalApes__factory } from "./typechain/factories/HonoraryOrbitalApes__factory";
import OAVDeployment from "./deployments/evmos/OAV.json";
import { OAV } from "./typechain/OAV";
import { OAV__factory } from "./typechain/factories/OAV__factory";
import { CouncilOfTheApes } from "./typechain/CouncilOfTheApes";
import { CouncilOfTheApes__factory } from "./typechain/factories/CouncilOfTheApes__factory";
import OAVDistributorDeployment from "./deployments/evmos/OAVDistributor.json";
import { OAVDistributor } from "./typechain/OAVDistributor";
import { OAVDistributor__factory } from "./typechain/factories/OAVDistributor__factory";
import OARewardsDistributorDeployment from "./deployments/evmos/OARewardsDistributor.json";
import { OARewardsDistributor } from "./typechain/OARewardsDistributor";
import { OARewardsDistributor__factory } from "./typechain/factories/OARewardsDistributor__factory";
import { OrbitalApesLimitedController } from "./typechain/OrbitalApesLimitedController";
import { OrbitalApesLimitedController__factory } from "./typechain/factories/OrbitalApesLimitedController__factory";
import { OrbitalApesCustomsController } from "./typechain/OrbitalApesCustomsController";
import { OrbitalApesCustomsController__factory } from "./typechain/factories/OrbitalApesCustomsController__factory";
import OrbitalApesDeployment from "./deployments/evmos/OrbitalApes.json";
import { OrbitalApes } from "./typechain/OrbitalApes";
import { OrbitalApes__factory } from "./typechain/factories/OrbitalApes__factory";
import { OrbitalApesCustoms } from "./typechain/OrbitalApesCustoms";
import { OrbitalApesCustoms__factory } from "./typechain/factories/OrbitalApesCustoms__factory";
import { OrbitalApesLimited } from "./typechain/OrbitalApesLimited";
import { OrbitalApesLimited__factory } from "./typechain/factories/OrbitalApesLimited__factory";
import { RafflePool } from "./typechain/RafflePool";
import { RafflePool__factory } from "./typechain/factories/RafflePool__factory";
import RafflePoolControllerDeployment from "./deployments/evmos/RafflePoolController.json";
import { RafflePoolController } from "./typechain/RafflePoolController";
import { RafflePoolController__factory } from "./typechain/factories/RafflePoolController__factory";
import RafflePoolFactoryDeployment from "./deployments/evmos/RafflePoolFactory.json";
import { RafflePoolFactory } from "./typechain/RafflePoolFactory";
import { RafflePoolFactory__factory } from "./typechain/factories/RafflePoolFactory__factory";
import { ERC1155 } from "./typechain/ERC1155";
import { ERC1155__factory } from "./typechain/factories/ERC1155__factory";
import { ERC20 } from "./typechain/ERC20";
import { ERC20__factory } from "./typechain/factories/ERC20__factory";
import { ERC721 } from "./typechain/ERC721";
import { ERC721__factory } from "./typechain/factories/ERC721__factory";

const emptyContract = {
  instance: undefined,
  factory: undefined,
};
const defaultProvider: providers.Provider | undefined = undefined;
export const ProviderContext = React.createContext<
  [
    providers.Provider | undefined,
    React.Dispatch<React.SetStateAction<providers.Provider | undefined>>
  ]
>([defaultProvider, () => {}]);
const defaultCurrentAddress: string = "";
export const CurrentAddressContext = React.createContext<
  [string, React.Dispatch<React.SetStateAction<string>>]
>([defaultCurrentAddress, () => {}]);
const defaultSigner: Signer | undefined = undefined;
export const SignerContext = React.createContext<
  [Signer | undefined, React.Dispatch<React.SetStateAction<Signer | undefined>>]
>([defaultSigner, () => {}]);
const defaultSymfoniContext: SymfoniContextInterface = {
  currentHardhatProvider: "",
  init: () => {
    throw Error("Symfoni context not initialized");
  },
  loading: false,
  messages: [],
  providers: [],
};
export const SymfoniContext = React.createContext<SymfoniContextInterface>(
  defaultSymfoniContext
);
export const HonoraryOrbitalApesContext =
  React.createContext<SymfoniHonoraryOrbitalApes>(emptyContract);
export const OAVContext = React.createContext<SymfoniOAV>(emptyContract);
export const CouncilOfTheApesContext =
  React.createContext<SymfoniCouncilOfTheApes>(emptyContract);
export const OAVDistributorContext =
  React.createContext<SymfoniOAVDistributor>(emptyContract);
export const OARewardsDistributorContext =
  React.createContext<SymfoniOARewardsDistributor>(emptyContract);
export const OrbitalApesLimitedControllerContext =
  React.createContext<SymfoniOrbitalApesLimitedController>(emptyContract);
export const OrbitalApesCustomsControllerContext =
  React.createContext<SymfoniOrbitalApesCustomsController>(emptyContract);
export const OrbitalApesContext =
  React.createContext<SymfoniOrbitalApes>(emptyContract);
export const OrbitalApesCustomsContext =
  React.createContext<SymfoniOrbitalApesCustoms>(emptyContract);
export const OrbitalApesLimitedContext =
  React.createContext<SymfoniOrbitalApesLimited>(emptyContract);
export const RafflePoolContext =
  React.createContext<SymfoniRafflePool>(emptyContract);
export const RafflePoolControllerContext =
  React.createContext<SymfoniRafflePoolController>(emptyContract);
export const RafflePoolFactoryContext =
  React.createContext<SymfoniRafflePoolFactory>(emptyContract);
export const ERC1155Context =
  React.createContext<SymfoniERC1155>(emptyContract);
export const ERC20Context = React.createContext<SymfoniERC20>(emptyContract);
export const ERC721Context = React.createContext<SymfoniERC721>(emptyContract);

export interface SymfoniContextInterface {
  init: (provider?: string) => void;
  loading: boolean;
  messages: string[];
  currentHardhatProvider: string;
  providers: string[];
}

export interface SymfoniProps {
  autoInit?: boolean;
  showLoading?: boolean;
  loadingComponent?: React.ReactNode;
}

export interface SymfoniHonoraryOrbitalApes {
  instance?: HonoraryOrbitalApes;
  factory?: HonoraryOrbitalApes__factory;
}

export interface SymfoniOAV {
  instance?: OAV;
  factory?: OAV__factory;
}

export interface SymfoniCouncilOfTheApes {
  instance?: CouncilOfTheApes;
  factory?: CouncilOfTheApes__factory;
}

export interface SymfoniOAVDistributor {
  instance?: OAVDistributor;
  factory?: OAVDistributor__factory;
}

export interface SymfoniOARewardsDistributor {
  instance?: OARewardsDistributor;
  factory?: OARewardsDistributor__factory;
}

export interface SymfoniOrbitalApesLimitedController {
  instance?: OrbitalApesLimitedController;
  factory?: OrbitalApesLimitedController__factory;
}

export interface SymfoniOrbitalApesCustomsController {
  instance?: OrbitalApesCustomsController;
  factory?: OrbitalApesCustomsController__factory;
}

export interface SymfoniOrbitalApes {
  instance?: OrbitalApes;
  factory?: OrbitalApes__factory;
}

export interface SymfoniOrbitalApesCustoms {
  instance?: OrbitalApesCustoms;
  factory?: OrbitalApesCustoms__factory;
}

export interface SymfoniOrbitalApesLimited {
  instance?: OrbitalApesLimited;
  factory?: OrbitalApesLimited__factory;
}

export interface SymfoniRafflePool {
  instance?: RafflePool;
  factory?: RafflePool__factory;
}

export interface SymfoniRafflePoolController {
  instance?: RafflePoolController;
  factory?: RafflePoolController__factory;
}

export interface SymfoniRafflePoolFactory {
  instance?: RafflePoolFactory;
  factory?: RafflePoolFactory__factory;
}

export interface SymfoniERC1155 {
  instance?: ERC1155;
  factory?: ERC1155__factory;
}

export interface SymfoniERC20 {
  instance?: ERC20;
  factory?: ERC20__factory;
}

export interface SymfoniERC721 {
  instance?: ERC721;
  factory?: ERC721__factory;
}

export const Symfoni: React.FC<SymfoniProps> = ({
  showLoading = true,
  autoInit = true,
  ...props
}) => {
  const [initializeCounter, setInitializeCounter] = useState(0);
  const [currentHardhatProvider, setCurrentHardhatProvider] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [signer, setSigner] = useState<Signer | undefined>(defaultSigner);
  const [provider, setProvider] = useState<providers.Provider | undefined>(
    defaultProvider
  );
  const [currentAddress, setCurrentAddress] = useState<string>(
    defaultCurrentAddress
  );
  const [fallbackProvider] = useState<string | undefined>(undefined);
  const [providerPriority, setProviderPriority] = useState<string[]>([
    "web3modal",
    "hardhat",
  ]);
  const [HonoraryOrbitalApes, setHonoraryOrbitalApes] =
    useState<SymfoniHonoraryOrbitalApes>(emptyContract);
  const [OAV, setOAV] = useState<SymfoniOAV>(emptyContract);
  const [CouncilOfTheApes, setCouncilOfTheApes] =
    useState<SymfoniCouncilOfTheApes>(emptyContract);
  const [OAVDistributor, setOAVDistributor] =
    useState<SymfoniOAVDistributor>(emptyContract);
  const [OARewardsDistributor, setOARewardsDistributor] =
    useState<SymfoniOARewardsDistributor>(emptyContract);
  const [OrbitalApesLimitedController, setOrbitalApesLimitedController] =
    useState<SymfoniOrbitalApesLimitedController>(emptyContract);
  const [OrbitalApesCustomsController, setOrbitalApesCustomsController] =
    useState<SymfoniOrbitalApesCustomsController>(emptyContract);
  const [OrbitalApes, setOrbitalApes] =
    useState<SymfoniOrbitalApes>(emptyContract);
  const [OrbitalApesCustoms, setOrbitalApesCustoms] =
    useState<SymfoniOrbitalApesCustoms>(emptyContract);
  const [OrbitalApesLimited, setOrbitalApesLimited] =
    useState<SymfoniOrbitalApesLimited>(emptyContract);
  const [RafflePool, setRafflePool] =
    useState<SymfoniRafflePool>(emptyContract);
  const [RafflePoolController, setRafflePoolController] =
    useState<SymfoniRafflePoolController>(emptyContract);
  const [RafflePoolFactory, setRafflePoolFactory] =
    useState<SymfoniRafflePoolFactory>(emptyContract);
  const [ERC1155, setERC1155] = useState<SymfoniERC1155>(emptyContract);
  const [ERC20, setERC20] = useState<SymfoniERC20>(emptyContract);
  const [ERC721, setERC721] = useState<SymfoniERC721>(emptyContract);
  useEffect(() => {
    if (messages.length > 0) console.debug(messages.pop());
  }, [messages]);

  const getProvider = async (): Promise<
    { provider: providers.Provider; hardhatProviderName: string } | undefined
  > => {
    let hardhatProviderName = "Not set";
    let _providerPriority = [...providerPriority];
    // Fallback provider
    if (fallbackProvider && autoInit && initializeCounter === 0) {
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") === null) {
        _providerPriority = _providerPriority.sort((a, b) => {
          return a === fallbackProvider ? -1 : b === fallbackProvider ? 1 : 0;
        });
      }
    }
    const provider = await _providerPriority.reduce(
      async (
        maybeProvider: Promise<providers.Provider | undefined>,
        providerIdentification
      ) => {
        let foundProvider = await maybeProvider;
        if (foundProvider) {
          return Promise.resolve(foundProvider);
        } else {
          switch (providerIdentification.toLowerCase()) {
            case "web3modal":
              try {
                const provider = await getWeb3ModalProvider();
                const web3provider = new ethers.providers.Web3Provider(
                  provider
                );
                hardhatProviderName = "web3modal";
                return Promise.resolve(web3provider);
              } catch (error) {
                return Promise.resolve(undefined);
              }
            case "hardhat":
              try {
                const provider = new ethers.providers.JsonRpcProvider({
                  url: "https://eth.bd.evmos.org:8545",
                });
                hardhatProviderName = "hardhat";
                return Promise.resolve(provider);
              } catch (error) {
                return Promise.resolve(undefined);
              }
            default:
              return Promise.resolve(undefined);
          }
        }
      },
      Promise.resolve(undefined)
    ); // end reduce
    return provider ? { provider, hardhatProviderName } : undefined;
  };
  const getSigner = async (
    _provider: providers.Provider,
    hardhatProviderName: string
  ): Promise<Signer | undefined> => {
    switch (hardhatProviderName) {
      case "web3modal":
        const web3provider = _provider as ethers.providers.Web3Provider;
        return await web3provider.getSigner();
      case "hardhat":
        return ethers.Wallet.fromMnemonic(
          "test test test test test test test test test test test junk"
        ).connect(_provider);
      default:
        return undefined;
    }
  };
  const getWeb3ModalProvider = async (): Promise<any> => {
    const providerOptions: IProviderOptions = {};
    const web3Modal = new Web3Modal({
      // network: "mainnet",
      cacheProvider: false,
      providerOptions, // required
    });
    return await web3Modal.connect();
  };

  useEffect(() => {
    let subscribed = true;
    const doAsync = async () => {
      const finish = (text: string) => {
        setLoading(false);
        setMessages((old) => [...old, text]);
      };
      const finishWithContracts = (text: string) => {
        setHonoraryOrbitalApes(getHonoraryOrbitalApes(_provider, _signer));
        setOAV(getOAV(_provider, _signer));
        setCouncilOfTheApes(getCouncilOfTheApes(_provider, _signer));
        setOAVDistributor(getOAVDistributor(_provider, _signer));
        setOARewardsDistributor(getOARewardsDistributor(_provider, _signer));
        setOrbitalApesLimitedController(
          getOrbitalApesLimitedController(_provider, _signer)
        );
        setOrbitalApesCustomsController(
          getOrbitalApesCustomsController(_provider, _signer)
        );
        setOrbitalApes(getOrbitalApes(_provider, _signer));
        setOrbitalApesCustoms(getOrbitalApesCustoms(_provider, _signer));
        setOrbitalApesLimited(getOrbitalApesLimited(_provider, _signer));
        setRafflePool(getRafflePool(_provider, _signer));
        setRafflePoolController(getRafflePoolController(_provider, _signer));
        setRafflePoolFactory(getRafflePoolFactory(_provider, _signer));
        setERC1155(getERC1155(_provider, _signer));
        setERC20(getERC20(_provider, _signer));
        setERC721(getERC721(_provider, _signer));
        finish(text);
      };
      if (!autoInit && initializeCounter === 0)
        return finish("Auto init turned off.");
      setLoading(true);
      setMessages((old) => [...old, "Initiating Symfoni React"]);
      const providerObject = await getProvider(); // getProvider can actually return undefined, see issue https://github.com/microsoft/TypeScript/issues/11094

      if (!subscribed || !providerObject)
        return finish("No provider or signer.");
      const _provider = providerObject.provider;
      setProvider(_provider);
      setMessages((old) => [
        ...old,
        "Useing " + providerObject.hardhatProviderName,
      ]);
      setCurrentHardhatProvider(providerObject.hardhatProviderName);
      const _signer = await getSigner(
        _provider,
        providerObject.hardhatProviderName
      );

      if (!subscribed || !_signer)
        return finishWithContracts("Provider, without signer.");
      setSigner(_signer);
      setMessages((old) => [...old, "Useing signer"]);
      const address = await _signer.getAddress();

      if (!subscribed || !address)
        return finishWithContracts("Provider and signer, without address.");
      setCurrentAddress(address);

      return finishWithContracts("Completed Symfoni context initialization.");
    };
    doAsync();
    return () => {
      subscribed = false;
    };
  }, [initializeCounter]);

  const getHonoraryOrbitalApes = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? HonoraryOrbitalApes__factory.connect(
          ethers.constants.AddressZero,
          _signer
        )
      : HonoraryOrbitalApes__factory.connect(
          ethers.constants.AddressZero,
          _provider
        );
    const contract: SymfoniHonoraryOrbitalApes = {
      instance: instance,
      factory: _signer ? new HonoraryOrbitalApes__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOAV = (_provider: providers.Provider, _signer?: Signer) => {
    const contractAddress = OAVDeployment.receipt.contractAddress;
    const instance = _signer
      ? OAV__factory.connect(contractAddress, _signer)
      : OAV__factory.connect(contractAddress, _provider);
    const contract: SymfoniOAV = {
      instance: instance,
      factory: _signer ? new OAV__factory(_signer) : undefined,
    };
    return contract;
  };
  const getCouncilOfTheApes = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? CouncilOfTheApes__factory.connect(ethers.constants.AddressZero, _signer)
      : CouncilOfTheApes__factory.connect(
          ethers.constants.AddressZero,
          _provider
        );
    const contract: SymfoniCouncilOfTheApes = {
      instance: instance,
      factory: _signer ? new CouncilOfTheApes__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOAVDistributor = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress = OAVDistributorDeployment.receipt.contractAddress;
    const instance = _signer
      ? OAVDistributor__factory.connect(contractAddress, _signer)
      : OAVDistributor__factory.connect(contractAddress, _provider);
    const contract: SymfoniOAVDistributor = {
      instance: instance,
      factory: _signer ? new OAVDistributor__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOARewardsDistributor = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      OARewardsDistributorDeployment.receipt.contractAddress;
    const instance = _signer
      ? OARewardsDistributor__factory.connect(contractAddress, _signer)
      : OARewardsDistributor__factory.connect(contractAddress, _provider);
    const contract: SymfoniOARewardsDistributor = {
      instance: instance,
      factory: _signer ? new OARewardsDistributor__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitalApesLimitedController = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? OrbitalApesLimitedController__factory.connect(
          ethers.constants.AddressZero,
          _signer
        )
      : OrbitalApesLimitedController__factory.connect(
          ethers.constants.AddressZero,
          _provider
        );
    const contract: SymfoniOrbitalApesLimitedController = {
      instance: instance,
      factory: _signer
        ? new OrbitalApesLimitedController__factory(_signer)
        : undefined,
    };
    return contract;
  };
  const getOrbitalApesCustomsController = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? OrbitalApesCustomsController__factory.connect(
          ethers.constants.AddressZero,
          _signer
        )
      : OrbitalApesCustomsController__factory.connect(
          ethers.constants.AddressZero,
          _provider
        );
    const contract: SymfoniOrbitalApesCustomsController = {
      instance: instance,
      factory: _signer
        ? new OrbitalApesCustomsController__factory(_signer)
        : undefined,
    };
    return contract;
  };
  const getOrbitalApes = (_provider: providers.Provider, _signer?: Signer) => {
    const contractAddress = OrbitalApesDeployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitalApes__factory.connect(contractAddress, _signer)
      : OrbitalApes__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitalApes = {
      instance: instance,
      factory: _signer ? new OrbitalApes__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitalApesCustoms = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? OrbitalApesCustoms__factory.connect(
          ethers.constants.AddressZero,
          _signer
        )
      : OrbitalApesCustoms__factory.connect(
          ethers.constants.AddressZero,
          _provider
        );
    const contract: SymfoniOrbitalApesCustoms = {
      instance: instance,
      factory: _signer ? new OrbitalApesCustoms__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitalApesLimited = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? OrbitalApesLimited__factory.connect(
          ethers.constants.AddressZero,
          _signer
        )
      : OrbitalApesLimited__factory.connect(
          ethers.constants.AddressZero,
          _provider
        );
    const contract: SymfoniOrbitalApesLimited = {
      instance: instance,
      factory: _signer ? new OrbitalApesLimited__factory(_signer) : undefined,
    };
    return contract;
  };
  const getRafflePool = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? RafflePool__factory.connect(ethers.constants.AddressZero, _signer)
      : RafflePool__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniRafflePool = {
      instance: instance,
      factory: _signer ? new RafflePool__factory(_signer) : undefined,
    };
    return contract;
  };
  const getRafflePoolController = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      RafflePoolControllerDeployment.receipt.contractAddress;
    const instance = _signer
      ? RafflePoolController__factory.connect(contractAddress, _signer)
      : RafflePoolController__factory.connect(contractAddress, _provider);
    const contract: SymfoniRafflePoolController = {
      instance: instance,
      factory: _signer ? new RafflePoolController__factory(_signer) : undefined,
    };
    return contract;
  };
  const getRafflePoolFactory = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress = RafflePoolFactoryDeployment.receipt.contractAddress;
    const instance = _signer
      ? RafflePoolFactory__factory.connect(contractAddress, _signer)
      : RafflePoolFactory__factory.connect(contractAddress, _provider);
    const contract: SymfoniRafflePoolFactory = {
      instance: instance,
      factory: _signer ? new RafflePoolFactory__factory(_signer) : undefined,
    };
    return contract;
  };
  const getERC1155 = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? ERC1155__factory.connect(ethers.constants.AddressZero, _signer)
      : ERC1155__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniERC1155 = {
      instance: instance,
      factory: _signer ? new ERC1155__factory(_signer) : undefined,
    };
    return contract;
  };
  const getERC20 = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? ERC20__factory.connect(ethers.constants.AddressZero, _signer)
      : ERC20__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniERC20 = {
      instance: instance,
      factory: _signer ? new ERC20__factory(_signer) : undefined,
    };
    return contract;
  };
  const getERC721 = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? ERC721__factory.connect(ethers.constants.AddressZero, _signer)
      : ERC721__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniERC721 = {
      instance: instance,
      factory: _signer ? new ERC721__factory(_signer) : undefined,
    };
    return contract;
  };
  const handleInitProvider = (provider?: string) => {
    if (provider) {
      setProviderPriority((old) =>
        old.sort((a, b) => {
          return a === provider ? -1 : b === provider ? 1 : 0;
        })
      );
    }
    setInitializeCounter(initializeCounter + 1);
  };
  return (
    <SymfoniContext.Provider
      value={{
        init: (provider) => handleInitProvider(provider),
        providers: providerPriority,
        currentHardhatProvider,
        loading,
        messages,
      }}
    >
      <ProviderContext.Provider value={[provider, setProvider]}>
        <SignerContext.Provider value={[signer, setSigner]}>
          <CurrentAddressContext.Provider
            value={[currentAddress, setCurrentAddress]}
          >
            <HonoraryOrbitalApesContext.Provider value={HonoraryOrbitalApes}>
              <OAVContext.Provider value={OAV}>
                <CouncilOfTheApesContext.Provider value={CouncilOfTheApes}>
                  <OAVDistributorContext.Provider value={OAVDistributor}>
                    <OARewardsDistributorContext.Provider
                      value={OARewardsDistributor}
                    >
                      <OrbitalApesLimitedControllerContext.Provider
                        value={OrbitalApesLimitedController}
                      >
                        <OrbitalApesCustomsControllerContext.Provider
                          value={OrbitalApesCustomsController}
                        >
                          <OrbitalApesContext.Provider value={OrbitalApes}>
                            <OrbitalApesCustomsContext.Provider
                              value={OrbitalApesCustoms}
                            >
                              <OrbitalApesLimitedContext.Provider
                                value={OrbitalApesLimited}
                              >
                                <RafflePoolContext.Provider value={RafflePool}>
                                  <RafflePoolControllerContext.Provider
                                    value={RafflePoolController}
                                  >
                                    <RafflePoolFactoryContext.Provider
                                      value={RafflePoolFactory}
                                    >
                                      <ERC1155Context.Provider value={ERC1155}>
                                        <ERC20Context.Provider value={ERC20}>
                                          <ERC721Context.Provider
                                            value={ERC721}
                                          >
                                            {showLoading && loading ? (
                                              props.loadingComponent ? (
                                                props.loadingComponent
                                              ) : (
                                                <div>
                                                  {messages.map((msg, i) => (
                                                    <p key={i}>{msg}</p>
                                                  ))}
                                                </div>
                                              )
                                            ) : (
                                              props.children
                                            )}
                                          </ERC721Context.Provider>
                                        </ERC20Context.Provider>
                                      </ERC1155Context.Provider>
                                    </RafflePoolFactoryContext.Provider>
                                  </RafflePoolControllerContext.Provider>
                                </RafflePoolContext.Provider>
                              </OrbitalApesLimitedContext.Provider>
                            </OrbitalApesCustomsContext.Provider>
                          </OrbitalApesContext.Provider>
                        </OrbitalApesCustomsControllerContext.Provider>
                      </OrbitalApesLimitedControllerContext.Provider>
                    </OARewardsDistributorContext.Provider>
                  </OAVDistributorContext.Provider>
                </CouncilOfTheApesContext.Provider>
              </OAVContext.Provider>
            </HonoraryOrbitalApesContext.Provider>
          </CurrentAddressContext.Provider>
        </SignerContext.Provider>
      </ProviderContext.Provider>
    </SymfoniContext.Provider>
  );
};
