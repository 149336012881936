import detectEthereumProvider from "@metamask/detect-provider";
import { useContext, useEffect, useState } from "react";

// Import auto generated context from Symphony file
import {
  OrbitalApesContext,
  OAVContext,
  OAVDistributorContext,
  OARewardsDistributorContext,
  OrbitalApesLimitedControllerContext,
  OrbitalApesLimitedContext,
  ERC20Context,
  CouncilOfTheApesContext,
  OrbitalApesCustomsContext,
  OrbitalApesCustomsControllerContext,
  RafflePoolControllerContext,
  RafflePoolContext,
} from "../hardhat/SymfoniContext";

import { chains } from "./chains";
import { config } from "./config";

export function useEVM() {
  const CHAINID = chains[config.chain].chainId;
  const orbitalApesContract = useContext(OrbitalApesContext);
  const oavContract = useContext(OAVContext);
  const distributorContract = useContext(OAVDistributorContext);
  const rewardDistributorContract = useContext(OARewardsDistributorContext);
  const limitedControllerContract = useContext(
    OrbitalApesLimitedControllerContext
  );
  const limitedContract = useContext(OrbitalApesLimitedContext);
  const customControllerContract = useContext(
    OrbitalApesCustomsControllerContext
  );
  const customContract = useContext(OrbitalApesCustomsContext);
  const erc20Contract = useContext(ERC20Context);
  const council = useContext(CouncilOfTheApesContext);
  const raffleController = useContext(RafflePoolControllerContext);
  const raffleContract = useContext(RafflePoolContext);

  const [isNetwork, setIsNetwork] = useState(false);
  const [address, setAddress] = useState(null);
  const [isMetamask, setIsMetamask] = useState(false);
  const [isInit, setInit] = useState(false);

  const CHAIN_PARAMS = chains[config.chain];

  async function addNetwork() {
    if (isMetamask && isNetwork) {
      return connectWallet();
    }
    const provider = await detectEthereumProvider();
    if (!provider) return;
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [CHAIN_PARAMS],
    });
  }

  async function connectWallet() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAddress(accounts[0]);
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }
      }
    }
  }

  useEffect(() => {
    const fetcher = async () => {
      const provider = await detectEthereumProvider();
      setIsNetwork(
        provider?.chainId?.toString().toLowerCase() ===
          CHAINID.toString().toLowerCase()
      );
      setAddress(provider?.selectedAddress);
      setIsMetamask(provider?.isMetaMask);
      setInit(true);
    };

    fetcher();
  }, [CHAINID, orbitalApesContract?.instance?.address]);

  return {
    isConnected: address && isNetwork,
    isNetwork,
    orbitalApesContract,
    isMetamask,
    addNetwork,
    address,
    connectWallet,
    isInit,
    distributorContract,
    oavContract,
    rewardDistributorContract,
    limitedControllerContract,
    limitedContract,
    erc20Contract,
    council,
    customContract,
    customControllerContract,
    raffleController,
    raffleContract,
  };
}
