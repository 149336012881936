import { GRAPH_URL } from "../constant/urls";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const UserTokensQuery = `
  query userTokens($address: String, $nftAddress: String) {
    tokens(first:1000, where: {owner: $address, nftAddress: $nftAddress}) {
      id
      tokenId
    }
  }
`;

export const UserTokens = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let objs = {
    address,
    nftAddress: "0x4c275ade386af17276834579b1a68146cef3c770",
  };

  const res = await client.query({
    query: gql(UserTokensQuery),
    variables: objs,
  });

  return res?.data?.tokens;
};
