import cs from "classnames";
import { ethers } from "ethers";
import { nFormatter } from "../helpers/formatNumber";
import { useEffect, useState } from "react";
import { handleError } from "../helpers/errors";
import { useEVM } from "../hooks/EVMhook";
import { dismissToast, loadingToast, successToast } from "./Toasts";

export default function StakeBox({ orbitalBalance }) {
  const [userRewards, setUserRewards] = useState(0);
  const [loading, setLoading] = useState(false);

  const { rewardDistributorContract, erc721Contract, address } = useEVM();

  useEffect(() => {
    function getInfo() {
      rewardDistributorContract?.instance
        ?.attach("0xc790360740E00E381B98464109019B9B86B6dE2e")
        ?.getRewardsBalances("0xd4949664cd82660aae99bedc034a0dea8a0bd517")
        .then((res) => {
          if (res) {
            setUserRewards(ethers?.utils?.formatEther(res));
          }
        });
    }

    if (address) {
      getInfo();
    }
  }, [address, erc721Contract?.instance, rewardDistributorContract?.instance]);

  async function handleClaim() {
    setLoading(true);
    try {
      const transaction = await rewardDistributorContract?.instance
        ?.attach("0xc790360740E00E381B98464109019B9B86B6dE2e")
        ?.claimRewards("0xd4949664cd82660aae99bedc034a0dea8a0bd517");
      const toastId = loadingToast("Claiming rewards...");
      rewardDistributorContract?.instance
        ?.attach("0xc790360740E00E381B98464109019B9B86B6dE2e")
        ?.provider?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("Rewards Claimed!");
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  return (
    <div
      style={{ minWidth: "50%" }}
      className="rounded bg-dark border-2 border-darkGray flex-1  p-4 gap-7 flex flex-col shadow"
    >
      <div className="flex gap-3 flex-1 w-full flex-col">
        <span className="text-purple font-semibold text-2xl">
          Claim My Orbit Rewards
        </span>
        <div className="flex text-white flex-1 justify-between">
          <span>Orbital Apes Balance</span>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <span>{orbitalBalance}</span>
        </div>
        <div className="flex text-white flex-1 justify-between">
          <span>My WEVMOS Rewards</span>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <span>{nFormatter(userRewards, 3)}</span>
        </div>
        <div className="flex mt-4 self-center">
          <button
            disabled={loading}
            onClick={() => handleClaim()}
            className={cs(
              "bg-purple rounded px-5 py-1 text-white font-medium",
              {
                "bg-opacity-50": loading,
              }
            )}
          >
            Claim
          </button>
        </div>
      </div>
    </div>
  );
}
