import cs from "classnames";
import { nFormatter } from "../helpers/formatNumber";

export default function Validator({
  userRewards,
  orbitalBalance,
  onClaim,
  loading,
}) {
  console.log("loading", loading);
  return (
    <div className="rounded flex-1 bg-dark border-2 border-darkGray  p-4 gap-7 flex flex-col shadow">
      <div className="flex gap-3 flex-1 w-full flex-col">
        <div className="flex justify-between items-center">
          <span className="text-purple font-semibold text-2xl">
            Claim Validator Rewards
          </span>
        </div>

        <div className="flex text-white flex-1 justify-between">
          <div className="flex items-end gap-1">
            {/* <img className="h-6  mt-0.5" alt="eth" src={OAVToken} /> */}
            <span>Orbital Apes Balance</span>
          </div>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <span>{orbitalBalance}</span>
        </div>
        <div className="flex text-white flex-1 justify-between">
          <span>My EVMOS Rewards</span>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <div className="flex gap-3">
            <span className="border h-7 rounded border-purple px-1.5">
              {nFormatter(userRewards["evmos"], 3)}
            </span>

            <button
              disabled={loading === "evmos"}
              onClick={() => onClaim("evmos")}
              className={cs(
                "bg-purple h-7 rounded px-5  text-white font-medium",
                {
                  "opacity-50": loading === "evmos",
                }
              )}
            >
              {loading === "evmos" ? "Loading..." : `Claim`}
            </button>
          </div>
        </div>
        <div className="flex text-white flex-1 justify-between">
          <span>My TORI Rewards</span>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <div className="flex gap-3">
            <span className="border h-7 rounded border-purple px-1.5">
              {nFormatter(userRewards["tori"], 3)}
            </span>

            <button
              onClick={() => onClaim("tori")}
              className={cs(
                "bg-purple h-7 rounded px-5  text-white font-medium",
                { "opacity-50": loading === "tori" }
              )}
            >
              {loading === "tori" ? "Loading..." : `Claim`}
            </button>
          </div>
        </div>
        <div className="flex text-white flex-1 justify-between">
          <span>My OSMO Rewards</span>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <div className="flex gap-3">
            <span className="border h-7 rounded border-purple px-1.5">
              {nFormatter(userRewards["osmo"], 3)}
            </span>

            <button
              onClick={() => onClaim("osmo")}
              className={cs(
                "bg-purple h-7 rounded px-5  text-white font-medium",
                { "opacity-50": loading === "osmo" }
              )}
            >
              {loading === "osmo" ? "Loading..." : `Claim`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
