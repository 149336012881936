import Logo from "../assets/placeholders/logo.png";
import Discord from "../assets/icons/discord.svg";
import Twitter from "../assets/icons/twitter.svg";
import Hamburger from "../components/Hamburger";
function MobileHeader({ show, setShow }) {
  return (
    <header
      style={{ backgroundColor: "#0C0C0C" }}
      className="flex px-8 w-full sticky z-30 top-0 shadow-sm justify-center "
    >
      <nav className="flex   items-center justify-between w-full">
        <div className="flex  items-center justify-between gap-3 py-0  w-full ">
          <a href="/">
            <img alt="logo" className="h-16 xl:mr-28" src={Logo} />
          </a>
          <div className="md:flex hidden items-center self-center gap-4">
            <a
              href="https://hub.orbitalapes.com"
              className=" px-5 py-2 hover:bg-darkGray text-white "
            >
              OA Hub
            </a>
            <a
              href="https://app.evmos.org/staking"
              className=" px-5 py-2 hover:bg-darkGray text-white "
            >
              Validator
            </a>
            <a
              target="_blank"
              href="https://www.orbitmarket.io/"
              className=" px-5 py-2 hover:bg-darkGray text-white "
              rel="noreferrer"
            >
              Marketplace
            </a>
            <a
              target="_blank"
              href="https://www.orbitrumble.com/"
              className=" px-5 py-2 hover:bg-darkGray text-white "
              rel="noreferrer"
            >
              Rumble
            </a>
            <a
              href="https://discord.gg/orbitalapes "
              className=" w-7 h-7 rounded-full"
            >
              <img className="w-full" alt="discord" src={Discord} />
            </a>

            <a
              href="https://twitter.com/OrbitalApes"
              className=" w-6 h-6 rounded-full"
            >
              <img className="w-full" alt="twitter" src={Twitter} />
            </a>
          </div>
          {!show && <Hamburger onClick={() => setShow(true)} />}
        </div>
      </nav>
    </header>
  );
}

export default MobileHeader;
