import Modal from "react-modal";
import Example from "../assets/placeholders/discordUser.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#202036",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

function DiscordInstructions({ closeModal }) {
  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-100 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              Discord Username
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>
          <div className="flex w-full border-t border-darkGray pb-5 pl-5 pr-5 flex-col">
            <div className="flex flex-col gap-2 mt-4">
              Please copy all the username including the number.
              <br />
              Example: "Mr.Sir#4966"
              <img alt="example" src={Example} className="w-full" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DiscordInstructions;
