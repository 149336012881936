// Testnet Values:
// export const ETH_CHAIN_ID = 9000;
// export const COSMOS_CHAIN_ID = "evmos_9000-4";
// export const EVMOS_DENOMINATION = "atevmos";
// export const NODE_URL = "https://rest.bd.evmos.dev:1317";
// export const EVMOS_JRPC = "https://tendermint.bd.evmos.dev:26657";
export const EVMOS_VALIDATOR = "evmosvaloper1mx9nqk5agvlsvt2yc8259nwztmxq7zjqep5khu";
export const ACRE_VALIDATOR = "acrevaloper1p6rtyjs3dr9eaknsqavskyz7dzjnesjxhrzyz9"

// Mainnet Values:
export const EVMOS_ETH_CHAIN_ID = "9001";
export const ACRE_ETH_CHAIN_ID = "9052";
export const EVMOS_COSMOS_CHAIN_ID = "evmos_9001-2";
export const ACRE_COSMOS_CHAIN_ID = "acre_9052-1";
export const EVMOS_DENOMINATION = "aevmos";
export const ACRE_DENOMINATION = "aacre";
export const EVMOS_REST_URL = "https://rest.bd.evmos.org:1317";
export const EVMOS_JRPC = "https://tendermint.bd.evmos.org:26657";
export const ACRE_JRPC = "https://rpc.acre.nodestake.top";
export const ACRE_REST_URL = "https://rest.cosmos.directory/acrechain"

// // Localnet values:
// export const ETH_CHAIN_ID = 9001;
// export const COSMOS_CHAIN_ID = "evmoslocalnet_9001-1";
// export const EVMOS_DENOMINATION = "aevmos";
// export const NODE_URL = "http://localhost:1317";
// export const EVMOS_JRPC = "http://localhost:26657";
// export const VALIDATOR = "evmosvaloper10z9ephaxvz56jrz8gss9psha6sf96gdwdznr2n";

export const EVMOS_FEE = {
  amount: "12000000000000000",
  denom: EVMOS_DENOMINATION,
  gas: "500000",
};

export const ACRE_FEE = {
  amount: "12000000000000000",
  denom: ACRE_DENOMINATION,
  gas: "500000",
};

export function convertAmountForFrontend(value, factor = 18) {
  if (value) {
    const temp = value / 10 ** factor;
    if (temp < 1) {
      return temp.toFixed(4);
    }
    if (temp < 100) {
      return temp.toFixed(4);
    }
    return temp.toFixed(2);
  }
  return 0;
}
