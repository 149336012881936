import WalletConnect from "../../components/wallet/walletConnect";
import { useLocalStorage } from "../../utils/wallets/useLocalStorage";
import {
  KEPLR_PROVIDER,
  METAMASK_PROVIDER,
  NO_PROVIDER,
  NO_PUBKEY,
  NO_WALLET,
} from "../../utils/wallets/constants";
import WebBanner from "../../assets/bgs/WebBanner.png";
import { useEffect, useState } from "react";
import { useEVM } from "../../hooks/EVMhook";
import Validator from "../../components/ValidatorBox";
import OwnerDiscord from "./OwnerDiscord";
import { fetchData } from "../../lambda/lambda";
import Web3 from "web3";
import Discord from "../../assets/icons/discord.svg";
import Twitter from "../../assets/icons/twitter.svg";
import { ethers } from "ethers";
import { UserTokens } from "../../graphql/api";
import {
  dismissToast,
  loadingToast,
  successToast,
} from "../../components/Toasts";
import { handleError } from "../../helpers/errors";
import StakeBox from "../../components/StakeBox";
import { connectMetamask } from "../../utils/wallets/metamask";
import { connectKeplr } from "../../utils/wallets/keplr";
// import Council from "./Council";

const web3 = new Web3(window.ethereum);
window?.ethereum?.request({ method: "eth_requestAccounts" });

const OWNER_ROLE_URL = "https://api.orbitalapes.com/isOwnerAddressLinked?";

export default function ApeClub() {
  const [provider, setProvider] = useLocalStorage("provider", NO_PROVIDER);
  const [wallet, setWallet] = useLocalStorage("wallet", NO_WALLET);
  // eslint-disable-next-line no-unused-vars
  const [pubkey, setPubkey] = useLocalStorage("pubkey", NO_PUBKEY);
  const [loading, setLoading] = useState(false);
  const [linkedData, setLinkedData] = useState({});
  const [rewards, setRewards] = useState(0);
  const [count, setCount] = useState(10);
  const [tokens, setTokens] = useState([]);
  const { orbitalApesContract, isConnected, rewardDistributorContract } =
    useEVM();

  console.log(tokens);

  async function claimRewards(network) {
    const networkAddress = {
      evmos: ethers.constants.AddressZero,
      tori: "0x205CF44075E77A3543abC690437F3b2819bc450a",
      osmo: "0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864"
    }

    setLoading(network);
    try {
      const _rewardAddress = networkAddress[network]
      const transaction = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.claimRewards(_rewardAddress);

      const toastId = loadingToast(`Claiming...`);
      rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.provider?.waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          successToast("Purchase complete!", {
            duration: 4000,
          });
          setLoading(false);
        });
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (provider === METAMASK_PROVIDER) {
      connectMetamask(setProvider, setWallet, setPubkey, true);
      return;
    }
    if (provider === KEPLR_PROVIDER) {
      connectKeplr(setProvider, setWallet, setPubkey);
      return;
    }
  });

  useEffect(() => {
    async function findAddress() {
      const addresses = await web3.eth.getAccounts();
      const currentAddress = addresses[0];
      const res = await fetchData(
        OWNER_ROLE_URL + `publicAddress=${encodeURIComponent(currentAddress)}`
      );
      setLinkedData(res);
    }
    findAddress();
  }, []);

  useEffect(() => {
    async function fetchInfo() {
      const evmosRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances(ethers?.constants?.AddressZero);
      const toriRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances("0x205CF44075E77A3543abC690437F3b2819bc450a");
      const osmoRewards = await rewardDistributorContract?.instance
        ?.attach("0x4C4869C43112768024A6516CbCD328D9808ecDD7")
        ?.getRewardsBalances("0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864");
      setRewards({evmos: ethers.utils.formatEther(evmosRewards), tori: ethers.utils.formatUnits(toriRewards, 6), osmo: ethers.utils.formatUnits(osmoRewards, 6)});
      const addresses = await web3.eth.getAccounts();
      const currentAddress = addresses[0];
      const ownedCount = await UserTokens(currentAddress);
      setTokens(ownedCount.map((o) => o.tokenId));
      setCount(ownedCount?.length || 0);
    }
    if (orbitalApesContract?.instance && rewardDistributorContract?.instance) {
      fetchInfo();
    }
  }, [orbitalApesContract?.instance, rewardDistributorContract?.instance]);
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="text-white pb-24 w-full  bg-darkest flex flex-col gap-12 items-center"
    >
      <div
        style={{ backgroundImage: `url(${WebBanner})` }}
        className="flex w-full mainbg flex-col"
      >
        <div className="gap-2  flex items-center flex-col bg-gradient-to-b from-darkest to-darkest via-transparent w-full">
          <div className="gap-2 py-16 flex items-center flex-col bg-darkest bg-opacity-70 w-full">
            <div className="flex flex-col md:flex-row px-4 max-w-7xl w-full md:items-start items-start gap-8 justify-between">
              <div className="flex gap-4  flex-1 flex-col">
                <span className="text-6xl uppercase text-purple font-bold">
                  OA OWNERS
                </span>
                <div className="flex flex-col gap-8">
                  <span className="font-semibold text-white text-3xl">
                    Claim rewards and access exclusive perks
                  </span>
                  <a
                    data-aos="fade-up"
                    href="https://www.orbitmarket.io/"
                    className="border-purple border-2 w-36 text-center bg-black bg-opacity-60 shadow font-medium md:text-lg px-5 py-2 rounded-full  text-white"
                  >
                    See in Orbit
                  </a>
                  <div className="flex pl-2 gap-4">
                    <a
                      href="https://discord.gg/orbitalapes "
                      className=" w-7 h-7 rounded-full"
                    >
                      <img className="w-full" alt="discord" src={Discord} />
                    </a>

                    <a
                      href="https://twitter.com/OrbitalApes"
                      className=" w-6 h-6 rounded-full"
                    >
                      <img className="w-full" alt="twitter" src={Twitter} />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div>

              </div> */}
              <WalletConnect
                setProvider={setProvider}
                setWallet={setWallet}
                setPubkey={setPubkey}
                wallet={wallet}
                provider={provider}
                hideKeplr={true}
              />
            </div>
          </div>
        </div>
      </div>

      {!isConnected && count > 0 && (
        <div className="flex gap-12 px-2 flex-col w-full max-w-7xl ">
          <div className="flex gap-4 p-2 flex-col">
            <span className="text-4xl font-semibold">Perks</span>

            <div className="flex md:flex-row flex-wrap justify-between flex-col gap-4 ">

              <Validator
                userRewards={rewards}
                orbitalBalance={count}
                onClaim={(n) => claimRewards(n)}
                loading={loading}
              />
              <StakeBox orbitalBalance={count} />
              <OwnerDiscord linkedData={linkedData} />

            </div>
          </div>
        </div>
      )}
      {count <= 0 && (
        <div className="flex text-2xl gap-4 font-medium text-center items-center flex-col">
          <span>
            You need to connect using a MetaMask wallet that contains OA NFTS to
            see this section
          </span>
          <div className="flex items-center gap-2 flex-col">
            <span>If you dont have an Orbital Ape</span>
            <a
              data-aos="fade-up"
              href="/mint"
              className="border-purple border-2 w-36 text-center bg-black bg-opacity-60 shadow font-medium md:text-lg px-5 py-2 rounded-full  text-white"
            >
              Mint Now
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
