import Header from "./Header";

function BaseLayout({ children }) {
  return (
    <div className="h-full">
      <Header />
      <main className="flex flex-col items-center">{children}</main>
    </div>
  );
}

export default BaseLayout;
