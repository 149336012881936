import { evmosToEth } from "@tharsis/address-converter";
import { EVMOS_ETH_CHAIN_ID } from "../../utils/network";
import { NO_PROVIDER, NO_PUBKEY, NO_WALLET } from "../../utils/wallets/constants";
import { generatePubkeyFromSignature } from "../../utils/wallets/metamask";
import { getAccount } from "../../utils/wallets/status";
import converter from "bech32-converting"

function acreToEth(address) {
  return converter('acre').toHex(address)
}
export async function prepareAccount(provider, wallet, pubkey, cosmosChainId, ethChainId) {
  if (wallet === NO_WALLET || provider === NO_PROVIDER) {
    alert("No wallet!");
    return null;
  }
  let account = await getAccount(wallet);
  if (account === null) {
    alert("Error getting account info!");
    return null;
  }
  if (!pubkey || pubkey === NO_PUBKEY) {
    const _eth_wallet = ethChainId === EVMOS_ETH_CHAIN_ID ? evmosToEth(wallet) : acreToEth(wallet)
    pubkey = await generatePubkeyFromSignature(_eth_wallet);
  }
  account["pubkey"] = pubkey;

  return {
    chain: { chainId: ethChainId, cosmosChainId: cosmosChainId },
    sender: account,
  };
}

export function getOAVBalance(evmosStaked, totalStaked) {
  return (evmosStaked / totalStaked) * 1000000;
}
