import { createTxRaw } from "@tharsis/proto";
import { prepareAccount } from "../../components/staking/utils";
import { ACRE_COSMOS_CHAIN_ID, EVMOS_COSMOS_CHAIN_ID, EVMOS_ETH_CHAIN_ID } from "../network";
import { KEPLR_PROVIDER, NO_PROVIDER, NO_PUBKEY, NO_WALLET } from "./constants";

const LEDGER_ERROR_MESSAGE =
  "Ledger is not compatible with this coinType right now";

async function doConnection(setProvider, setWallet, setPubkey, active_chain) {
  if (!window.getOfflineSignerOnlyAmino || !window.keplr) {
    alert("Error with Keplr: Please install keplr extension");
    return;
  }
  try {
    const COSMOS_CHAIN_ID = active_chain === 'evmos' ? EVMOS_COSMOS_CHAIN_ID : ACRE_COSMOS_CHAIN_ID
    await window.keplr.enable(COSMOS_CHAIN_ID);
    const offlineSigner = window.getOfflineSigner(COSMOS_CHAIN_ID);
    const wallets = await offlineSigner.getAccounts();
    setProvider(KEPLR_PROVIDER);
    setWallet(wallets[0].address);
    setPubkey(Buffer.from(wallets[0].pubkey).toString("base64"));
  } catch (e) {
    // There's currently not support for hardware wallets in keplr
    if (e.message === LEDGER_ERROR_MESSAGE) {
      setProvider(NO_PROVIDER);
      setWallet(NO_WALLET);
      setPubkey(NO_PUBKEY);
    }
  }
}

export async function connectKeplr(setProvider, setWallet, setPubkey, active_chain) {
  await doConnection(setProvider, setWallet, setPubkey, active_chain);
  window.addEventListener("keplr_keystorechange", () => {
    connectKeplr(setProvider, setWallet, setPubkey);
  });
}

export async function signWithKeplrCosmosTx(chain, sender, tx) {
  // TODO: display error if not enabled
  await window.keplr.enable(chain.cosmosChainId);

  const sign = await window.keplr.signDirect(
    chain.cosmosChainId,
    sender.accountAddress,
    {
      bodyBytes: tx.signDirect.body.serializeBinary(),
      authInfoBytes: tx.signDirect.authInfo.serializeBinary(),
      chainId: chain.cosmosChainId,
      accountNumber: sender.accountNumber,
    },
    { isEthereum: true }
  );

  return createTxRaw(sign.signed.bodyBytes, sign.signed.authInfoBytes, [
    new Uint8Array(Buffer.from(sign.signature.signature, "base64")),
  ]);
}

export async function signWithKeplrString(provider, wallet, pubkey, string) {
  // TODO: display error if not enabled
  const values = await prepareAccount(provider, wallet, pubkey, EVMOS_COSMOS_CHAIN_ID, EVMOS_ETH_CHAIN_ID);

  await window.keplr.enable(values.chain.cosmosChainId);
  const signature = await window.keplr.signArbitrary(
    "evmos_9001-2",
    values.sender.accountAddress,
    string
  );
  return signature;
}
